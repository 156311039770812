/*main elements*/
$primary-color: #37474f;
$primary-light-color: #78909c;
$primary-dark-color: #263238;

/*secondary elements*/
$secondary-color: #ff6d00;
$secondary-light-color: #ff9e40;
$secondary-dark-color: #c43c00;

/*basic background*/
$background-color: #ffffff;
/*cards and like this*/
$surface-color: #f5f5f5;

$error-color: #dd2c00;

$text-color: #fffff;
$text-color-dark: #757575;
$text-contrast-color: #000000;
